import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Split Jerk 5-5-5-5 to a 5RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Fight Gone Bad”`}</strong></p>
    <p>{`3 Rounds, 1:00 per exercise of:`}</p>
    <p>{`Wall Ball (20/14)`}</p>
    <p>{`SDHP (75/55)`}</p>
    <p>{`Box Jump (24/20)`}</p>
    <p>{`Push Press (75/55)`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`1:00 Rest`}</p>
    <p>{`Score = Total Reps.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Annual free Thanksgiving morning WOD will be Thursday at
9:00am.  All other classes and open gym will be cancelled for the day as
well as the 6:00am class on Friday.  Classes will be back to normal
starting at 10:30am on Friday.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start Nov 26th.  For more info email
Eric at: fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      